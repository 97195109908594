import React from 'react'
import { ThreeBounce } from 'better-react-spinkit'
import ReactApexChart from 'react-apexcharts'

class CandelStickChart extends React.Component {

    constructor(props) {
        super(props);

        super(props);

        this.state = {
            options: {
                xaxis: {
                    type: 'datetime'
                },
                yaxis: {
                    tooltip: {
                        enabled: true
                    }
                },
                animations: {
                    initialAnimation: {
                        enabled: false
                    }
                }
            },
            series: [{
                data: []
            }],
            isloading: true
        }
        this.renderChart = this.renderChart.bind(this)
    }
    componentDidMount() {
        if (this.props.data !== undefined && (this.props.data.length > 0)) {
            const datas = this.CreateData(this.props.data)
            let { series } = this.state
            let obj = Object.assign(series, {})
            obj[0].data = datas
            this.setState({
                series: obj,
                isloading: false
            })
        }
    }
    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.data !== this.props.data) {
            const datas = this.CreateData(nextProps.data)
            let { series } = this.state
            let obj = Object.assign(series, {})
            obj[0].data = datas
            this.setState({
                isloading: true,
                series: obj,
            }, () => {
                this.setState({
                    isloading: false
                })
                this.renderChart()
            })
            return true
        }
    }
    CreateData = (data) => {
        let datas = []
        data.map((item, index) => {
            datas.push({
                x: item.date,
                y: [item.open, item.high, item.low, item.close]
            })
        })
        if(datas.length > 500) {
            datas = datas.slice(Math.abs(data.length - 500));
        }
        return datas
    }
    renderChart = () => {
        return (
            !this.state.isloading && <ReactApexChart options={this.state.options} series={this.state.series} type="candlestick" height="350" />
        )
    }
    render() {
        return (
            <div>
                <div id="chart">
                    {this.renderChart()}
                </div>
                <div id="html-dist">
                </div>
            </div>
        );
    }
}
export default CandelStickChart