import storeRedux from 'redux/store'
import Service from "api"
import _ from 'lodash';
import {getStore} from "helper";
import {
  actionCreatorsHistory, actionCreatorsToken, actionCreatorsBasicCoin,
  actionCreatorsMessage,
  actionCreatorsLoading,
  actionCreatorsCoin
} from "redux/actions";
import { showLoading, hideLoading } from 'react-redux-loading-bar'

let reduxState

storeRedux.subscribe(() => {
  reduxState = storeRedux.getState()
})
export default class ReduxServervice {
  static async initData() {
    this.callDispatchAction(showLoading())
    let dataCached = getStore('UPDATE_CONTENT');
    if (dataCached === null) {
      // never cached search
      const result = await Service.getToken()
      const info = await Service.getTokenEth()
      const fullHistory = await Service.getFullHistoryToken()
      const demo = _.orderBy(fullHistory, ['filledTimestamp'], ['desc']);
      let paramETH = {
        symbol: 'ETH',
        currency: 'USD'
      }
      let paramWETH = {
        symbol: 'WETH',
        currency: 'USD'
      }
      const WETH = await Service.getPriceInfo(paramWETH)
      const ETH = await Service.getPriceInfo(paramETH)
      let data = {
        gobalHistory: { ...demo },
        fullHistory: { ...demo }
      }
      let token = {
        ...result,
        ...info,
        logoId: null
      }
      let coins = await Service.getAllCoin()
      let dataBasic = {
        isloading: false,
        ETH: {
          ...ETH
        },
        WETH: {
          ...WETH
        },
        coins: { ...coins }
      }
      this.callDispatchAction(actionCreatorsHistory.loading(data))
      this.callDispatchAction(actionCreatorsToken.loading(token))
      this.callDispatchAction(actionCreatorsBasicCoin.loading(dataBasic))
      this.callDispatchAction(hideLoading())
    } else {
      let logoId = null
      let symbol = dataCached.symbol
      let address = dataCached.address
      const result = await Service.getToken(address)
      const info = await Service.getTokenEth(address)
      const fullHistory = await Service.getFullHistoryToken(symbol)
      const demo = _.orderBy(fullHistory, ['filledTimestamp'], ['desc']);
      let paramETH = {
        symbol: 'ETH',
        currency: 'USD'
      }
      let paramWETH = {
        symbol: 'WETH',
        currency: 'USD'
      }
      let coins = await Service.getAllCoin()
      const WETH = await Service.getPriceInfo(paramWETH)
      const ETH = await Service.getPriceInfo(paramETH)
      let data = {
        gobalHistory: { ...demo },
        fullHistory: { ...demo }
      }
      let token = {
        ...result,
        ...info,
        logoId: dataCached.logoId
      }
      let dataBasic = {
        isloading: false,
        ETH: {
          ...ETH
        },
        WETH: {
          ...WETH
        },
        coins: { ...coins }
      }
      this.callDispatchAction(actionCreatorsHistory.loading(data))
      this.callDispatchAction(actionCreatorsToken.loading(token))
      this.callDispatchAction(actionCreatorsBasicCoin.loading(dataBasic))
    }
    this.callDispatchAction(hideLoading())
  }
  static async getAllCoin() {
    const { reducerCoin } = storeRedux.getState()
    let temp = Object.assign(reducerCoin)
    let data = await Service.getAllCoin()
    temp = { ...data }
    this.callDispatchAction(actionCreatorsCoin.loading(temp))
  }
  static async searchTokenByAddress(search) {
    const { reducerLoading } = reduxState;
    let tmpLoading = { ...reducerLoading }
    tmpLoading.updateContent = true
    this.callDispatchAction(showLoading())
    this.callDispatchAction(actionCreatorsLoading.loading(tmpLoading))
    let logoId = null
    const result = await Service.getToken(search);
    if (result.hasOwnProperty("errorMessage")) {
      const message = {
        isShow: true,
        message: result.errorMessage
      };
      this.callDispatchAction(actionCreatorsMessage.loading(message));
    }
    const info = await Service.getTokenEth(search);
    let paramCheckCoin = {
      symbol: info.symbol,
      currency: 'USD'
    }
    const checkCoin = await Service.getPriceInfo(paramCheckCoin)
    if (!checkCoin.hasOwnProperty("message")) {
      logoId = checkCoin.data.id
    }
    const fullHistory = await Service.getFullHistoryToken(info.symbol);
    const demo = _.orderBy(fullHistory, ["filledTimestamp"], ["desc"]);
    let data = {
      gobalHistory: { ...demo },
      fullHistory: { ...demo }
    };
    let token = {
      ...result,
      ...info,
      logoId: logoId
    };
    tmpLoading.updateContent = false
    tmpLoading.symbol = info.symbol
    tmpLoading.address = info.address
    this.callDispatchAction(actionCreatorsLoading.loading(tmpLoading))
    this.callDispatchAction(actionCreatorsHistory.loading(data));
    this.callDispatchAction(actionCreatorsToken.loading(token));
    this.callDispatchAction(hideLoading())
  }
  static async callDispatchAction(action) {
    storeRedux.dispatch(action)
  }
}