import React from 'react'
import "./style.css";
import { connect } from 'react-redux'
import { isAddress, isHashValid } from "helper"
import { actionCreatorsHistory, actionCreatorsMessage } from "redux/actions";
import _ from 'lodash';
class Filter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search: ''
    }
    this.handleFilte = this.handleFilte.bind(this)
    this.handelChange = this.handelChange.bind(this)
  }
  handleFilte = (e) => {
    const { history, dispatch } = this.props
    const {search} = this.state
    const filter = { ...history }
    const gobalHistory = filter.gobalHistory
    if ((this.state.search).length > 0 & this.state.search.substring(0, 2) === "0x") {
        let tempData = _.map(gobalHistory, (item) => {
          if (item.txID.includes(search) || (item.metadata.makerToken.address).includes(search) ||
          (item.metadata.takerToken.address).includes(search)
        ) {
            return item
          }
        })
        tempData = _.without(tempData, undefined)
        filter.fullHistory = tempData
        dispatch(actionCreatorsHistory.loading(filter))
    } else {
      const message = {
        isShow: true,
        message: 'value input not empty'
      }
      dispatch(actionCreatorsMessage.loading(message))
    }
  }
  handelChange = (e) => {
   if(e.key === "Enter") {
     e.preventDefault()
     this.handleFilte(e)
   }else {
    this.setState({
      search: e.target.value.trim()
    })
   }
  }

  render() {
    return (
      <div className="search">
        <form className="form-inline filter">
          <input type="text" onChange={this.handelChange} onKeyPress={this.handelChange} className="form-control customInput" placeholder="Enter Address/TxtHash" aria-label="Search" />
          <input onClick={this.handleFilte} type="button" className="btn" value="Apply" />
        </form>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  history: state.reducerHistory
})

export default connect(mapStateToProps)(Filter)