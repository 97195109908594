import React from 'react';
import Images from 'asset/images'
import ReactPaginate from 'react-paginate';
import { format } from 'timeago.js';
import { FadingCircle } from 'better-react-spinkit'
import { connect } from 'react-redux'
import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { convertWeiToBalance, formatNumberLeftZero } from 'helper'
class Table extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      history: [],
      isloading: true,
      offset: 1,
      data: [],
      dataPagination: [],
      total: 0
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.reducerHistory) {
      this.setState({
        data: nextProps.reducerHistory.fullHistory,
        isloading: false
      }, () => {
        const total = Object.keys(this.state.data).length
        const pages = Math.ceil(total / 21)
        const data = this.Paginator(this.state.data, 1, 21)
        this.setState({
          total: pages,
          dataPagination: data
        })
      })
    }
  }
  handlePageChange = async (pageNumber = 1) => {
    const data = this.Paginator(this.state.data, pageNumber.selected + 1, 21)
    this.setState({
      dataPagination: data,
      offset: pageNumber.selected
    })
  }
  printItem = (data) => {
    const demo = Object.values(data)
    if (demo.length > 0) {
      return demo.map((item, i) => {
        let maker = (item.metadata.makerToken).decimals
        let taker = (item.metadata.takerToken).decimals
        let choose = maker < taker ? maker : taker
        const from = item.hasOwnProperty('isV2') ? item.takerAddress : item.addressFiller 
        const to =  item.signedOrder.makerAddress || ''

        // console.log('item', item)
        return (
          <tr key={i}>
            <th scope="col"> <div className="long-text"><a href={`https://etherscan.io/tx/${item.txID}`} target="_blank">{item.txID}</a></div></th>
            <th scope="col">{format(item.filledTimestamp * 1000)}</th>
            <th scope="col" className="col-green">
              <div className="long-text"><a href={`https://etherscan.io/address/${from}`} target="_blank">{from}</a> </div>
            </th>
            <th scope="col" className='col-center'><a href={`https://etherscan.io/address/${item.metadata.makerToken.address}`}  target="_blank" >{item.metadata.makerToken.symbol}</a></th>
            <th scope="col" className='col-center' className="col-center col-img"><img src={`${Images.icArowup}`} /></th>
            <th scope="col" className='col-center' ><a href={`https://etherscan.io/address/${item.metadata.takerToken.address}`}  target="_blank" >{item.metadata.takerToken.symbol}</a></th>
            <th scope="col" className="col-center"><div className="long-text"><a href={`https://etherscan.io/address/${to}`} target="_blank">{to}</a></div></th>
            <th scope="col" className="col-right">{formatNumberLeftZero(convertWeiToBalance(item.txBalance, choose))}</th>
            {/* <th scope="col" className="col-right">{formatNumberLeftZero(item.txBalance, choose)}</th> */}
          </tr>
        )
      })
    } else {
      return (
        <tr>
        <th scope="col" colSpan="6" className="col-center">
          <h3 className="no-history"> NO HISTORY TRANSACTION</h3>
        </th>
        </tr>
      )
    }

  }
  Paginator = (items, page = 1, per_page = 21) => {
    const data = items && Object.values(items)
    const page1 = page || 1,
      offset = (page1 - 1) * per_page,
      paginatedItems = data.slice(offset).slice(0, per_page)
    return paginatedItems
  }

  render() {
    const { isloading, dataPagination, total, offset } = this.state
    const isShowNext = offset + 1 < total ? <FontAwesomeIcon icon={faChevronRight} /> : null
    const isShowPrevious = offset >= 1 ? <FontAwesomeIcon icon={faChevronLeft} /> : null
    return (
      !isloading  && 
        <div className="col-sm-12">
          <div className="pagination-tab float-right">
            {total > 1 && <ReactPaginate
              previousLabel={isShowPrevious}
              nextLabel={isShowNext}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={total}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageChange}
              containerClassName={'pagination custom-pagination'}
              subContainerClassName={''}
              pageLinkClassName={''}
              activeClassName={'active'}
            />}
          </div>
          <div className="table-responsive table-custom">
            <table className="table">
              <thead>
                <tr>
                  <th>txthash</th>
                  <th>age</th>
                  <th>from</th>
                  <th>maker Token</th>
                  <th className='short-td'></th>
                  <th>taker Token</th>
                  <th>to</th>
                  <th className="col-right">quantity</th>
                </tr>
              </thead>
              <tbody>
                {this.printItem(dataPagination)}
              </tbody>
            </table>
          </div>
          <div className="pagination-tab float-right">
            {total > 1 && <ReactPaginate
              previousLabel={isShowPrevious}
              nextLabel={isShowNext}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={total}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageChange}
              containerClassName={'pagination custom-pagination'}
              subContainerClassName={''}
              pageLinkClassName={''}
              activeClassName={'active'}
            />}
          </div>
        </div>
      // <div className="container"><div className="loading"><FadingCircle size={50} /></div></div>

    )
  }
}
const mapStateToProps = (state) => ({
  ...state,
})

export default connect(mapStateToProps)(Table)