import { types } from "../constants/constant";
import { initialState } from "../constants/initialState";

export const reducerHistory = (state = initialState.history, action) => {
  const { data } = state;
  const { type, payload } = action;

  switch (type) {
    case types.GET_HISTORY: {
      return {
        ...state,
        ...payload
      };
    }
  }

  return state;
};
