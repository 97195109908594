// Import stuff
import React from "react";
import Images from "asset/images";
import "./style.css";
import {
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import LineChart from "./lineChart";
import CandelStickChart from "./candelStickChart";
import { connect } from "react-redux";
import {
  loadOrderListBasedOnTimeInterval,
  getTimeFromPeriod,
  getNodeFromPeriod
} from "helper";
import { PERIOD_CHART, CHART_TYPE } from "helper/globalConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      dropdownOpen: false,
      dropdownOpenHours: false,
      data: [],
      isloading: true,
      currentSelect: [],
      currentSelectCandle: [],
      isLine: true,
      isCandle: false,
      isPreNode: 24,
      isType: PERIOD_CHART.ALL,
      isMinsCarret: false,
      isHoursCarret: false,
      showChart: true,
      selectedPriod: ""
    };
    this.toggle = this.toggle.bind(this);
    this.toggleHour = this.toggleHour.bind(this);
    this.handlChangeTime = this.handlChangeTime.bind(this);
    this.handelSwitchType = this.handelSwitchType.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.history !== this.props.history) {
      const history = Object.values(this.props.candelData);
      if (history.length > 0) {
        const decimal = history[0].metadata.takerToken.decimals;
        const timeCandel = getTimeFromPeriod(PERIOD_CHART.ALL, "line");
        const node = getNodeFromPeriod(PERIOD_CHART.ALL, "line");
        let arrayCandel = loadOrderListBasedOnTimeInterval(
          decimal,
          history,
          timeCandel
        );
        this.setState({
          data: this.props.history.data,
          currentSelect: arrayCandel,
          currentSelectCandle: arrayCandel,
          isloading: false,
          isPreNode: node,
          showChart: true,
          selectedPriod: PERIOD_CHART.ALL,
          selectedPriodHours: false,
          selectedPriodMin: false
        });
      } else {
        this.setState({
          showChart: false
        });
      }
    }
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      isMinsCarret: !this.state.isMinsCarret,
      selectedPriodHours: false,
      selectedPriodMin: true
    });
  }
  toggleHour() {
    this.setState({
      dropdownOpenHours: !this.state.dropdownOpenHours,
      isHoursCarret: !this.state.isHoursCarret,
      selectedPriodHours: true,
      selectedPriodMin: false
    });
  }
  handlChangeTime = type => {
    const { isCandle} = this.state;
    const history = Object.values(this.props.candelData);
    if(history.length > 0) {
      if (!isCandle) {
        const history = Object.values(this.props.candelData);
        const decimal = history[0].metadata.takerToken.decimals;
        const timeCandel = getTimeFromPeriod(type, CHART_TYPE.CHART_LINE);
        const arrayCandel = loadOrderListBasedOnTimeInterval(
          decimal,
          history,
          timeCandel
        );
        const node = getNodeFromPeriod(type, CHART_TYPE.CHART_LINE);
        if (arrayCandel.length > node) {
          let demo = arrayCandel.slice(Math.abs(arrayCandel.length - node));
          this.setState({
            currentSelectCandle: demo,
            selectedPriod: type
          });
        } else {
          this.setState({
            currentSelectCandle: arrayCandel,
            selectedPriod: type
          });
        }
      } else {
        if (this.props.candelData) {
          const history = Object.values(this.props.candelData);
          const decimal = history[0].metadata.takerToken.decimals;
          const timeCandel = getTimeFromPeriod(type, CHART_TYPE.CHART_CANDLE_STICK);
          const arrayCandel = loadOrderListBasedOnTimeInterval(
            decimal,
            history,
            timeCandel
          );
          this.setState({
            currentSelectCandle: arrayCandel,
            selectedPriod: type
          });
        }
      }
    }
  };
  handelSwitchType = type => {
    const history = Object.values(this.props.candelData);
    if(history.length > 0) {
      if (type === CHART_TYPE.CHART_LINE) {
        this.setState(
          {
            isLine: true,
            isCandle: false
          },
          () => {
            this.handlChangeTime(PERIOD_CHART.ALL);
          }
        );
      }
      if (type === CHART_TYPE.CHART_CANDLE_STICK) {
        this.setState({
          isLine: false,
          isCandle: true
        });
        const history = Object.values(this.props.candelData);
        const decimal = history[0].metadata.takerToken.decimals;
        const timeCandel = getTimeFromPeriod(
          PERIOD_CHART.ONE_MONTH,
          CHART_TYPE.CHART_CANDLE_STICK
        );
        const arrayCandel = loadOrderListBasedOnTimeInterval(
          decimal,
          history,
          timeCandel
        );
        this.setState({
          currentSelectCandle: arrayCandel,
          selectedPriod: PERIOD_CHART.ONE_MONTH
        });
      }
    }
  };
  render() {
    const {
      isloading,
      isLine,
      isCandle,
      currentSelect,
      showChart,
      selectedPriod,
      selectedPriodMin,
      selectedPriodHours
    } = this.state;
    const activeLine = isLine ? "active" : "";
    const activeCandel = isCandle ? "active" : "";
    const listHours = [
      PERIOD_CHART.ONE_HOURS,
      PERIOD_CHART.SIX_HOURS,
      PERIOD_CHART.TWELVE_HOURS
    ];
    const listMin = [PERIOD_CHART.FIVE_MUNITES, PERIOD_CHART.THIRTY_MUNITES];
    const min = selectedPriod !== "" && selectedPriodMin && listMin.indexOf(selectedPriod) > -1 ? selectedPriod : "Mins"
    const hour = selectedPriod !== "" && listHours.indexOf(selectedPriod) > -1 && selectedPriodHours ? selectedPriod : "Hours"
    const fivemin = PERIOD_CHART.FIVE_MUNITES === selectedPriod ? "active" : ''
    const thistymin = PERIOD_CHART.THIRTY_MUNITES === selectedPriod ? "active" : ''
    const onehour = PERIOD_CHART.ONE_HOURS === selectedPriod ? "active" : ''
    const sixhour = PERIOD_CHART.SIX_HOURS === selectedPriod ? "active" : ''
    const oneday = PERIOD_CHART.ONE_DAY === selectedPriod ? "active" : ''
    const oneweek = PERIOD_CHART.ONE_WEEK === selectedPriod ? "active" : ''
    const onemonth = PERIOD_CHART.ONE_MONTH === selectedPriod ? "active" : ''
    return !isloading && currentSelect.length !== 0 && (
      <div className="container">
        <div className="custom-border">
          <div className="panel panel-default chart">
            <div className="panel-heading row">
              <div className="col-md-6 first">
                <Nav>
                  <Dropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                  >
                    <DropdownToggle nav>
                      {min}
                      <FontAwesomeIcon
                        icon={
                          !this.state.isMinsCarret ? faCaretDown : faCaretUp
                        }
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className={
                          fivemin
                        }
                        onClick={() =>
                          this.handlChangeTime(PERIOD_CHART.FIVE_MUNITES)
                        }
                      >
                        5M
                      </DropdownItem>
                      <DropdownItem
                        className={
                          thistymin
                        }
                        onClick={() =>
                          this.handlChangeTime(PERIOD_CHART.THIRTY_MUNITES)
                        }
                      >
                        30M
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    nav
                    isOpen={this.state.dropdownOpenHours}
                    toggle={this.toggleHour}
                  >
                    <DropdownToggle nav>
                      {hour}
                      <FontAwesomeIcon
                        icon={
                          !this.state.isHoursCarret ? faCaretDown : faCaretUp
                        }
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className={
                          onehour
                        }
                        onClick={() =>
                          this.handlChangeTime(PERIOD_CHART.ONE_HOURS)
                        }
                      >
                        1H
                      </DropdownItem>
                      <DropdownItem
                        className={
                          PERIOD_CHART.SIX_HOURS === selectedPriod ? "active" : ''
                        }
                        onClick={() =>
                          this.handlChangeTime(PERIOD_CHART.SIX_HOURS)
                        }
                      >
                        6H
                      </DropdownItem>
                      <DropdownItem
                        className={
                          sixhour
                        }
                        onClick={() =>
                          this.handlChangeTime(PERIOD_CHART.TWELVE_HOURS)
                        }
                      >
                        12H
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <NavItem>
                    <NavLink
                      className={
                        oneday
                      }
                      onClick={() => this.handlChangeTime(PERIOD_CHART.ONE_DAY)}
                      href="#"
                    >
                      1D
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        oneweek
                      }
                      onClick={() => this.handlChangeTime(PERIOD_CHART.ONE_WEEK)}
                      href="#"
                    >
                      1W
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        onemonth
                      }
                      onClick={() =>
                        this.handlChangeTime(PERIOD_CHART.ONE_MONTH)
                      }
                      href="#"
                    >
                      1M
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {isLine && (
                      <NavLink
                        className={
                          PERIOD_CHART.ALL === selectedPriod ? "active" : ''
                        }
                        onClick={() => this.handlChangeTime(PERIOD_CHART.ALL)}
                        href="#"
                      >
                        All
                      </NavLink>
                    )}
                  </NavItem>
                </Nav>
              </div>
              <div className="col-md-6 second">
                <Nav className="float-right">
                  <NavItem>
                    <NavLink
                      className={activeLine}
                      onClick={() => this.handelSwitchType(CHART_TYPE.CHART_LINE)}
                      href="#"
                    >
                      Line
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeCandel}
                      onClick={() => this.handelSwitchType(CHART_TYPE.CHART_CANDLE_STICK)}
                      href="#"
                    >
                      CandelStick
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <img alt="zoom" src={`${Images.icZoom}`} />
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div>
          <div className="panel-body">
            {showChart && isLine && (
              <LineChart
                node={this.state.isPreNode}
                data={this.state.currentSelectCandle}
              />
            )}
            {showChart && isCandle && (
              <CandelStickChart
                node={this.state.isPreNode}
                data={this.state.currentSelectCandle}
              />
            )}
            {!showChart && (
              <div className="center">
                <h1 className="empty">EMPTY HISTORY CHART</h1>
              </div>
            )}
            {/* <div id="chartdiv">
              {!showChart && (
                <div className="center">
                  <h1 className="empty">EMPTY HISTORY CHART</h1>
                </div>
              )}
            </div> */}
            {/* <div className="over-brand" /> */}
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    history: state.reducerToken,
    candelData: state.reducerHistory.gobalHistory
  };
};
export default connect(
  mapStateToProps
)(Chart);
