import React from "react";
import "./style.css";
import Search from "component/search";
import Banner from "component/banner";
import Images from "asset/images";
import {removeStore} from 'helper'
import ReduxService from 'helper/globalRedux'
import { connect } from "react-redux";

class Header extends React.Component {
  handelHome = async () => {
    removeStore('UPDATE_CONTENT')
    await ReduxService.initData()
  }
  render() {
    return (
      <header>
        <div className="container top-header">
          <section className="row">
            <div className="col-md-7">
              <div className="logo">
              <a onClick={this.handelHome} href="#">
                <img alt="logo" src={Images.imgLogo} alt="Foo eating a sandwich." />
                <span className="Sologan">HB DEX SCAN</span>
                </a>
              </div>
            </div>
            <div className="col-md-4 offset-md-1">
              <Search />
            </div>
          </section>
        </div>
        <div className="line-notice one" />
        <div className="container">
          <section className="row notice">
            <div className="col-lg-6">
              <div className="leftNotice">
                <img  alt="check" src={Images.icCheck} alt="description of image" />
                <span className="spacing">Hb-dex.com is exclusively deigned to track transaction history of HB DEX</span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="rightNotice float-right">
                <img  alt="images" src={Images.icNotice} alt="description of image" />
                <span className="spacing">More information about: < a href="https://www.hb-wallet.com/dex" target="_blank">HB DEX</a></span>
              </div>
            </div>
          </section>
        </div>
        <div className="line-notice two" />
        <div className='row'>
        <Banner/>
        </div>
      </header>
    );
  }
}
const mapStateToProps = state => ({
  ...state
});

export default  connect(mapStateToProps)(Header);