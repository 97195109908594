// Initial state of the store
export const initialState = {
  history: {},
  token: {},
  message: {
    isShow: false,
    message: []
  },
  basicCoin: {
    isloading: true,
    ETH: {},
    WETH: {},
    coins: {
    }
  },
  loading: {
    updateContent: false,
    symbol: 'HBWALLET',
    address: '0xec7ba74789694d0d03d458965370dc7cf2fe75ba'
  },
};
