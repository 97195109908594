import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import { routerReducer } from "react-router-redux";
import thunkMiddleware from "redux-thunk";
import logger from "redux-logger";
import { loadingBarMiddleware } from 'react-redux-loading-bar'

const middleWare = [thunkMiddleware];
if (process.env.REACT_APP_ENVIROMENT === "DEV") {
  middleWare.push(logger);
}
const store = createStore(rootReducer, applyMiddleware(...middleWare));
export default store;
