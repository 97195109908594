import { types } from "../constants/constant";
import { initialState } from "../constants/initialState";

export const reducerBasicCoin = (state = initialState.basicCoin, action) => {
  const { data } = state;
  const { type, payload } = action;

  switch (type) {
    case types.GET_BASICCOIN: {
      return {
        ...state,
        ...payload
      };
    }
  }
  return state;
};
