import { PERIOD_CHART, CHART_TYPE, WEB_CONFIG } from "./globalConstant";
var Web3 = require("web3");
var web3 = new Web3();
web3.setProvider(new web3.providers.HttpProvider(WEB_CONFIG.HOST_INFURA));

const addEmptyCandle = (NoDistance, timeStamp, interval, value) => {
  let candleList = [];
  // emply candle will take the close value of last candle
  for (let i = 0; i < NoDistance; i++) {
    candleList.push({
      date: new Date((timeStamp + i * interval) * 1000),
      open: value,
      close: value,
      high: value,
      low: value,
      volume: 0
    });
  }
  return candleList;
};
// get list of order base on time interval
// orders : list of unshorted orders, interval = miliseconds
export const loadOrderListBasedOnTimeInterval = (
  decimals,
  orders,
  interval
) => {
  let candleList = []; // list of candle
  if (orders && orders.length > 0) {
    let tempTimeStamp = Number(orders[orders.length - 1].filledTimestamp);
    let leftTime = tempTimeStamp % interval;
    tempTimeStamp = tempTimeStamp - leftTime;
    let orderEachCandle = []; // list of order in certain time

    for (let i = orders.length - 1; i >= 0; i--) {
      let _time = Number(orders[i].filledTimestamp);
      if (_time < tempTimeStamp + interval) {
        orderEachCandle.push(orders[i]);
      } else {
        let temp = filterCandle(decimals, orderEachCandle, interval);
        if (temp) {
          candleList.push(temp);
        }
        orderEachCandle = []; // reset only work when orderEachCandle is not reference by other array
        tempTimeStamp += interval;
        let NoOf5m = Math.floor((_time - tempTimeStamp) / interval);
        if (NoOf5m > 0) {
          // add empty list5m
          let result = addEmptyCandle(
            NoOf5m,
            tempTimeStamp,
            interval,
            candleList[candleList.length - 1].close
          ); // calculate empty candle
          candleList = candleList.concat(result);
          tempTimeStamp += NoOf5m * interval;
        }
        orderEachCandle.push(orders[i]);
      }
    }
    // console.log("orderEachCandle", orderEachCandle);

    if (orderEachCandle.length > 0) {
      let temp = filterCandle(decimals, orderEachCandle, interval);
      candleList.push(temp);
    }
    // console.log("tempTimeStamp end", tempTimeStamp);

    const currentTimeStamp = Math.floor(Date.now() / 1000);
    // console.log("current time", currentTimeStamp);
    if (currentTimeStamp > tempTimeStamp + interval) {
      let NoOf5m = Math.floor((currentTimeStamp - tempTimeStamp) / interval);
      // console.log("NoOf5m", NoOf5m);
      tempTimeStamp += interval;
      if (NoOf5m > 0) {
        // add empty list5m
        let result = addEmptyCandle(
          NoOf5m,
          tempTimeStamp,
          interval,
          candleList[candleList.length - 1].close
        ); // calculate empty candle
        candleList = candleList.concat(result);
        tempTimeStamp += NoOf5m * interval;
      }
    }

    if (candleList.length === 1) {
      let recordTemp = {
        date: new Date(
          (Number(orders[orders.length - 1].filledTimestamp) - interval) * 1000
        ),
        open: orders[0].price,
        close: orders[0].price,
        high: orders[0].price,
        low: orders[0].price,
        volumn: 0
      };
      candleList.unshift(recordTemp);
    }
  }
  // console.log("list candel", candleList);
  return candleList;
};
export const convertWeiToBalance = (strValue, iDecimal = 18) => {
  return parseFloat(strValue) / Math.pow(10, iDecimal);
};
const filterCandle = (decimals, orders, interval) => {
  if (orders.length === 0 || orders.length === null) {
    return null;
  }
  let _price = Number(orders[0].price);
  let _leftTime = Number(orders[0].filledTimestamp) % interval;
  let _tempTime = Number(orders[0].filledTimestamp) - _leftTime;
  let result = {
    date: new Date(Number(_tempTime) * 1000), // time of candle will be the filledTime of the first order
    open: _price,
    close: Number(orders[orders.length - 1].price),
    high: _price,
    low: _price,
    volume: Number(convertWeiToBalance(orders[0].txBalance, decimals))
  };
  // console.log('filter result1: ', result)
  for (let i = 1; i < orders.length; i++) {
    _price = Number(orders[i].price);
    if (result.high < _price) {
      result.high = _price;
    }
    if (result.low > _price) {
      result.low = _price;
    }
    result.volume += Number(convertWeiToBalance(orders[i].txBalance, decimals));
  }
  return result;
};
export const getTimeFromPeriod = (
  periodChart = PERIOD_CHART.ALL,
  chartType = CHART_TYPE.CHART_LINE
) => {
  // time in seconds
  let period = 60;
  if (chartType === CHART_TYPE.CHART_LINE) {
    switch (periodChart) {
      case PERIOD_CHART.FIVE_MUNITES:
      case PERIOD_CHART.THIRTY_MUNITES:
        period = period / 3;
        break;
      case PERIOD_CHART.ONE_HOURS:
      case PERIOD_CHART.SIX_HOURS:
      case PERIOD_CHART.TWELVE_HOURS:
        period = period * 5;
        break;
      case PERIOD_CHART.ONE_DAY:
      case PERIOD_CHART.ONE_WEEK:
        period = period * 60;
        break;
      case PERIOD_CHART.ONE_MONTH:
        period = period * 180;
        break;
      case PERIOD_CHART.ALL:
        period = period * 1440;
        break;
      default:
        period = period * 60;
        break;
    }
  } else {
    switch (periodChart) {
      case PERIOD_CHART.FIVE_MUNITES:
        period = period * 5;
        break;
      case PERIOD_CHART.THIRTY_MUNITES:
        period = period * 30;
        break;
      case PERIOD_CHART.ONE_HOURS:
        period = period * 60;
        break;
      case PERIOD_CHART.SIX_HOURS:
        period = period * 360;
        break;
      case PERIOD_CHART.TWELVE_HOURS:
        period = period * 720;
        break;
      case PERIOD_CHART.ONE_DAY:
        period = period * 1440;
        break;
      case PERIOD_CHART.ONE_WEEK:
        period = period * 10080;
        break;
      case PERIOD_CHART.ONE_MONTH:
        period = period * 43200;
        break;
      default:
        period = period * 5;
        break;
    }
  }
  // console.log("getTimeFromPeriod: ", periodChart, period);
  return period;
};

export const isAddress = function(address) {
  // function isAddress(address) {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    // check if it has the basic requirements of an address
    return false;
  } else if (
    /^(0x)?[0-9a-f]{40}$/.test(address) ||
    /^(0x)?[0-9A-F]{40}$/.test(address)
  ) {
    // If it's all small caps or all all caps, return "true
    return true;
  } else {
    // Otherwise check each case
    return isChecksumAddress(address);
  }
};
const isChecksumAddress = function(address) {
  // Check each case
  address = address.replace("0x", "");
  var addressHash = web3.sha3(address.toLowerCase());
  for (var i = 0; i < 40; i++) {
    // the nth letter should be uppercase if the nth digit of casemap is 1
    if (
      (parseInt(addressHash[i], 16) > 7 &&
        address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash[i], 16) <= 7 &&
        address[i].toLowerCase() !== address[i])
    ) {
      return false;
    }
  }
};
export const isHashValid = (
  value = "0xffe02ee4c69edf1b340fcad64fbd6b37a7b9e265"
) => {
  return value.length === 66 && value.substring(0, 2) === "0x";
};
export const getNodeFromPeriod = (
  periodChart = PERIOD_CHART.ALL,
  chartType = CHART_TYPE.CHART_LINE
) => {
  let node = 3; // each node is 20 seconds, 1 minute = 60 seconds / 20 seconds = 3 nodes
  if (chartType === CHART_TYPE.CHART_LINE) {
    switch (periodChart) {
      case PERIOD_CHART.FIVE_MUNITES:
        node = 3 * 5; // each node is 20 seconds
        break;
      case PERIOD_CHART.THIRTY_MUNITES:
        node = 3 * 30; // each node is 20 seconds
        break;
      case PERIOD_CHART.ONE_HOURS:
        node = 12; // each node is 5 minutes
        break;
      case PERIOD_CHART.SIX_HOURS:
        node = 12 * 6; // each node is 5 minutes
        break;
      case PERIOD_CHART.TWELVE_HOURS:
        node = 12 * 12; // each node is 5 minutes
        break;
      case PERIOD_CHART.ONE_DAY:
        node = 24; // each node is an hour
        break;
      case PERIOD_CHART.ONE_WEEK:
        node = 24 * 7; // each node is an hour
        break;
      case PERIOD_CHART.ONE_MONTH:
        node = 8 * 30; // each node is 3 hours
        break;
      case PERIOD_CHART.ALL:
        node = 9999; // each node is a day
        break;
    }
  }
  // console.log("periodNode", node);
  return node;
};

export const numberWithCommas = value => {
  if (value !== undefined) {
    let n = Number(value);
    return n.toLocaleString();
  }
};
export const round = (value, precision) => {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const formatPriceNumber = (strNumber, decimalFormat = 8) => {
  let first = '0'
  let second = ''
  decimalFormat = decimalFormat < 8 ? decimalFormat : 8
  isNaN(Number(strNumber)) && (strNumber = 0)
  strNumber = Number(strNumber).toFixed(decimalFormat)
  let arrDigit = String(strNumber).split('.')
  first = arrDigit[0]
  if (arrDigit.length > 1) {
    second = arrDigit[1]
    if (first.length + second.length > 9) { // length of integer part and decimal part greater than 9
      second = arrDigit[1].substring(0, 9 - first.length)
    }
  }
  first = numberWithCommasInt(first)
  return second.length === 0 ? first : [first, second].join('.')
}
export const formatNumber = (strNumber, decimalFormat = 8) => {
  let first = '0'
  let second = '00'
  decimalFormat = decimalFormat < 8 ? decimalFormat : 8
  isNaN(Number(strNumber)) && (strNumber = 0)
  strNumber = Number(strNumber).toFixed(10)
  let arrDigit = String(strNumber).split('.')
  if (arrDigit.length > 1) {
    first = numberWithCommasInt(arrDigit[0])
    second = arrDigit[1].substring(0, decimalFormat).replace(/\.?0+$/, '')
  } else if (arrDigit.length === 1) {
    first = numberWithCommasInt(arrDigit[0])
  }
  return second.length === 0 ? first : [first, second].join('.')
}

export const numberWithCommasInt = (strNumber) => {
  return strNumber ? strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
}
export const formatNumberLeftZero = (strNumber, decimalFormat = 8) => {
  let first = '0'
  let second = ''
  decimalFormat = decimalFormat < 8 ? decimalFormat : 8
  isNaN(Number(strNumber)) && (strNumber = 0)
  strNumber = Number(strNumber).toFixed(decimalFormat).replace(/\.?0+$/, '')
  let arrDigit = String(strNumber).split('.')
  first = arrDigit[0]
  if (arrDigit.length > 1) {
    second = arrDigit[1]
    if (first.length + second.length > 9) { // length of integer part and decimal part greater than 9
      second = arrDigit[1].substring(0, 9 - first.length).replace(/\.?0+$/, '')
    }
  }
  first = numberWithCommasInt(first)
  return second.length === 0 ? first : [first, second].join('.')
}
export const saveStore = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
}
export const getStore = (key) => {
  var result = localStorage.getItem(key);
  return JSON.parse(result)
}
export const removeStore = (key) => {
  localStorage.removeItem(key);
}

export const convertObjectToArray = (objConvert) => {
  return Object.keys(objConvert).map(function (key) { return objConvert[key] })
}