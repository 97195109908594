const Images = {
  imgArrow: require("./Arrow-up-small.svg"),
  imgLogo: require("./HBIT-no-txt.svg"),
  icFaceBook: require("./social/facebook-footer.svg"),
  icDiscord: require("./social/discord-footer.svg"),
  icBitcoin: require("./social/bitcoin-footer.svg"),
  icLinkedin: require("./social/linkedin-footer.svg"),
  icMedium: require("./social/medium-footer.svg"),
  icQuora: require("./social/quora-footer.svg"),
  icReddit: require("./social/reddit-footer.svg"),
  icTelegram: require("./social/telegram-footer.svg"),
  icTwitter: require("./social/twitter-footer.svg"),
  icYoutube: require("./social/youtube-footer.svg"),
  icCheck: require("./Checked.svg"),
  icNotice: require("./Caution.svg"),
  icEmail: require("./social/email.svg"),
  icBlog: require("./social/Blog.svg"),
  icSlack: require("./social/Slack.svg"),
  icFacebookcl: require("./social/Facebook.svg"),
  icTwittercl: require("./social/Twitter.svg"),
  icGithub: require("./social/github.svg"),
  icTelegramcl: require("./social/Telegram.svg"),
  icRedditcl: require("./social/Reddit.svg"),
  icDiscordcl: require("./social/Discord.svg"),
  icLinkedincl: require("./social/Lindekin.svg"),
  icYoutubecl: require("./social/Youtube.svg"),
  icWhitepaper: require("./social/whitepaper.svg"),
  icArowup: require("./Arrow-up-small.svg"),
  icZoom: require("./Fullscreen.svg"),
  banner: require('./hb-dex.gif')
};
export default Images;
