import { types } from "../constants/constant";
import { initialState } from "../constants/initialState";

export const reducerToken = (state = initialState.token, action) => {
  const { data } = state;
  const { type, payload } = action;
  switch (type) {
    case types.GET_TOKEN: {
      return {
        ...state,
        ...payload
      };
    }
  }

  return state;
};
