import React from 'react'
import { Wave } from 'better-react-spinkit'
import ReactApexChart from 'react-apexcharts'

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          stacked: true,
          events: {
            selection: function (chart, e) {
              console.log(new Date(e.xaxis.min))
            }
          },
        },
        colors: ['#007bff'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        xaxis: {
          type: 'datetime'
        },
        animations: {
          initialAnimation: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: 'WETH',
          data:[]
        }
      ],
      isloading: true
    }
    this.renderChart = this.renderChart.bind(this)
  }
  componentDidMount() {
    if(this.props.data !== undefined && (this.props.data.length > 0)) {
     const datas =  this.CreateData(this.props.data)
     let { series } = this.state
     let obj = Object.assign( series , {})
     obj[0].data = datas
     this.setState({
      series: obj,
      isloading: false
     })
    }
  }
componentWillReceiveProps(nextProps, nextState) {
  if(nextProps.data !== this.props.data) {
    const datas = this.CreateData(nextProps.data)
     let { series } = this.state
     let obj = Object.assign( series , {})
     obj[0].data = datas
     this.setState({
      isloading: true,
      series: obj,
     },()=>{
       this.setState({
         isloading: false
       })
      this.renderChart()
     })
    return true
  }
}
CreateData =(data) => {
  let datas = []
  data.map((item, index)=> {
    datas.push([(item.date).getTime(), Number(item.close)])
  })
  return datas
} 
renderChart =()=> {
return (
  !this.state.isloading && <ReactApexChart options={this.state.options} series={this.state.series} type="area" height="350" />
)
}
  render() {
    return (
      <div>
        <div id="chart">
       {this.renderChart()}
        </div>
        <div id="html-dist">
        </div>
      </div>
    );
  }
  }
export default LineChart