import React from 'react';
import Tablet from 'component/table/index'
import Chart from 'component/chart-new'
import Token from "component/token";
import Message from "component/message";
import { connect } from 'react-redux'
import { Wave } from 'better-react-spinkit'
class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isloading: true
    }
  }
  componentDidMount() {
    this.setState({ isloading: false })
  }
  render() {
    const { isloading } = this.state
    const {history, token, basicCoin} = this.props
    return (
      !isloading && history &&
        <main>
          <section className="row">
          <Message/>
          </section>
          <section className="row">
            <Token basic={basicCoin} data={token} />
          </section>
          <section className="row">
          <div id="divChart">
         
          </div>
          <Chart />
          </section>
          <section className="row">
            <Tablet data={history.fullHistory} />
          </section>
        </main >
        //<div className="container"><div className="loading"><Wave size={50} /></div></div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    history:  state.reducerHistory,
    message: state.reducerMessage,
    token: state.reducerToken,
    basicCoin: state.reducerBasicCoin
  };
}
export default connect(mapStateToProps, null)(Main)