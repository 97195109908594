export const CHART_TYPE = {
  CHART_LINE: "line",
  CHART_CANDLE_STICK: "candleStick",
  CHART_DEPTH: "depth"
};
export const PERIOD_CHART = {
  FIVE_MUNITES: "5M",
  THIRTY_MUNITES: "30M",
  ONE_HOURS: "1H",
  SIX_HOURS: "6H",
  TWELVE_HOURS: "12H",
  ONE_DAY: "1D",
  ONE_WEEK: "1W",
  ONE_MONTH: "1M",
  ALL: "ALL",
  MUNITE: "munite",
  HOUR: "hour"
};
export const WETH = {
  address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
};
// console.log('env', process.env )
export const WEB_CONFIG = {
  HOST_API: process.env.REACT_APP_HOST_API || 'https://dex-api.hb-wallet.io',
  HOST_TS_API: process.env.REACT_APP_HOST_TS_API || 'https://dex-api-ts.hb-wallet.io',
  HOST_ETHPLORER: 'https://api.ethplorer.io',
  HOST_INFURA: 'https://mainnet.infura.io/v3/97fb3fbbfb194a12bf821fedcb088612',
  NUMBER_PAGIN: 21,
  ADDRESS_DEFAULT: '0xEc7ba74789694d0d03D458965370Dc7cF2FE75Ba',
  SYMBOL: 'HBWALLET',
  URL_IMAGE_COIN: 'https://s2.coinmarketcap.com/static/img/coins/128x128/'

}
