import React from 'react';
import './style.css'
import Images from 'asset/images'
class footer extends React.Component {
  render() {
    return (
      <footer className="footer row justify-content-sm-center">
      <div className="container row">

        <div className="col-lg-6">
          <div className="footerLeft">
            <span className="version">Coppyright @ 2019 by Bacoor. All rights reserved </span>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="social">
            <ul className="listSocial float-right">
              <a className="iconSocial" href="https://twitter.com/HBWallet_ETH" target="_blank"><img src={Images.icTwitter} /> </a>
              <a className="iconSocial" href="https://t.me/hb_wallet" target="_blank"><img src={Images.icTelegram}/></a>
              <a className="iconSocial" href="https://www.reddit.com/r/hbwallet" target="_blank"><img src={Images.icReddit}/></a>
              <a className="iconSocial" href="https://www.linkedin.com/company/hbwallet/" target="_blank"><img src={Images.icDiscord}/></a>
              <a className="iconSocial" href="https://www.linkedin.com/company/hbwallet/" target="_blank"><img src={Images.icLinkedin}/></a>
              <a className="iconSocial" href="https://www.youtube.com/hbwallet?sub_confirmation=1" target="_blank"><img src={Images.icYoutube}/></a>
              <a className="iconSocial" href="#"><img src={Images.icQuora}/></a>
              <a className="iconSocial" href="#"><img src={Images.icMedium}/></a>
              <a className="iconSocial" href="#"><img src={Images.icBitcoin}/></a>
            </ul>
          </div>
        </div>
        </div>
      </footer>
    )   
  }
}

export default footer