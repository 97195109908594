import React, { Component } from 'react'
import './style.css'
import Images from "asset/images";

class Banner extends Component {
render () {
return (
    <div className='col-md-12 container'>
        <div className='banner img-responsive'>
        <a href='https://www.hb-wallet.com/download' target='_blank'>
            <img className='' src={Images.banner}/>
        </a>
        </div>
    </div>
    )
}
}
export default Banner