import React from 'react'
import "./style.css";
import { connect } from 'react-redux'
import { actionCreatorsMessage } from "redux/actions";
class Message extends React.Component {
    handelRemove = () => {
        const { dispatch } = this.props
        const message = {
            isShow: false,
            message: ''
        }
        dispatch(actionCreatorsMessage.loading(message))
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.message !== this.props.message.message) {
            if (this.props.message.message !== '') {
                setTimeout(() => {
                    this.removeMessage()
                }, 5000);
            }
        }
    }
    removeMessage = () => {
        const { dispatch } = this.props
        const message = {
            isShow: false,
            message: ''
        }
        dispatch(actionCreatorsMessage.loading(message))
    }
    render() {
        const { message } = this.props
        return (
            message && message.isShow &&
            <div className="col-lg-12">
                <div className="message">
                    <div className={`alert alert-danger alert-dismissible fade show`} role="alert">
                        {message.message}
                        <button onClick={this.handelRemove} type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span className="btnX" aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    message: state.reducerMessage
})

export default connect(mapStateToProps)(Message)