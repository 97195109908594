import { types } from "../constants/constant";
import { initialState } from "../constants/initialState";

export const reducerCoin = (state = initialState.basicCoin.coins, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_ALLCOIN: {
      return {
        ...state,
       ...payload
      };
    }
  }
  return state;
};
