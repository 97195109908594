import React from 'react'
import "./style.css"
class SearchSuggest extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.keyword != this.props.keyword) {
            return true
        }
        return false
    }
    handelSelectedCoin = (item) => {
        this.props.handelChildSuggess(item)
    }
    renderItem = (data, keyword) => {
        const demo = Object.values(data)
        let coins = []
        const items = demo.filter((item) => {
            if (((item.name).toLowerCase().includes(keyword.toLowerCase()) || ((item.symbol).toLowerCase()).includes(keyword.toLowerCase()))
                && item.status === 1) {
                coins.push(item)
            }
        })
        return coins.length > 0 && coins.map((item, i) => {
            return (
                <li onClick={() => this.handelSelectedCoin(item)} className="list-group-item item" key={i}>{item.name}/{item.symbol}</li>
            )
        })
    }
    render() {
        const { keyword, data, isShow } = this.props
        return (
            keyword !== '' && keyword != undefined && isShow &&  <div id="search-4" className="search-suggess">
                <ul className="list-group">{this.renderItem(data, keyword)}</ul>
            </div>
        )
    }
}
export default SearchSuggest