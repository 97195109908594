export const types = {
  LOADING: "LOAD",
  ADD: "ADD",
  REMOVE: "REMOVE",
  FILTER: "FILTER",
  GET_MESSAGE: "SHOW_MESSAGE",
  GET_TOKEN: "GET_TOKEN",
  GET_HISTORY: "SHOW_HISTORY",
  GET_BASICCOIN: "GET_BASICCOIN",
  UPDATE_CONTENT: "UPDATE_CONTENT",
  GET_ALLCOIN: "GET_ALLCOIN"
};
