import React from 'react';
import './App.css';
import Footer from './layout/footer';
import Header from './layout/header';
import Main from './layout/main';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faIgloo } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux'
import { FadingCircle } from 'better-react-spinkit'
import ReduxService from 'helper/globalRedux'
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import LoadingBar from 'react-redux-loading-bar'
library.add(faIgloo);

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
});

class App extends React.Component {
  async componentDidMount() {
    await ReduxService.initData()
  }
  render() {
    const { isUpdate } = this.props
    return (
      <I18nextProvider i18n={i18next}>
      <div className="interContainer">
      <LoadingBar  style={{ backgroundColor: '#007bff', height: '3px' }} />
        {/* {isUpdate.updateContent && <div className="loading"><FadingCircle size={50} /></div>} */}
        <Header />
        <div className="container main">
          <Main />
        </div>
        <Footer />
      </div>
      </I18nextProvider>
    );
  }
}
const mapStateToProps = (state) => ({
  isUpdate: state.reducerLoading,
})

export default connect(mapStateToProps)(App);
