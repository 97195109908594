import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { isAddress } from "helper";
import {
  actionCreatorsMessage,
  actionCreatorsHistory,
  actionCreatorsToken,
  actionCreatorsLoading
} from "redux/actions";
import { connect } from "react-redux";
import Service from "api";
import _ from "lodash";
import SearchSuggest from "component/search-suggesst";
import ReduxService from 'helper/globalRedux'
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.handelChildSuggess = this.handelChildSuggess.bind(this);
    this.handelOnChange = this.handelOnChange.bind(this);
    this.input = React.createRef();
    this.state = {
      searchKey: '',
      isShow: true
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.searchKey != this.state.searchKey) {
      return true
    }
    return false
  }
  handelKeyPress = async e => {
    if (e.key === "Enter") {
      const { dispatch, reducerLoading } = this.props;
      const search = e.target.value.trim();
      let tmpLoading = { ...reducerLoading }
      tmpLoading.updateContent = true
      dispatch(actionCreatorsLoading.loading(tmpLoading))
      e.preventDefault();
      if (search.length > 0) {
        if (isAddress(search)) {
          let logoId = null
          const result = await Service.getToken(search);
          if (result.hasOwnProperty("errorMessage")) {
            const message = {
              isShow: true,
              message: result.errorMessage
            };
            dispatch(actionCreatorsMessage.loading(message));
          }
          const info = await Service.getTokenEth(search);

          let paramCheckCoin = {
            symbol: info.symbol,
            currency: 'USD'
          }
          const checkCoin = await Service.getPriceInfo(paramCheckCoin)
          if (!checkCoin.hasOwnProperty("message")) {
            logoId = checkCoin.data && checkCoin.data.id || null
          }
          const fullHistory = await Service.getFullHistoryToken(info.symbol);
          const demo = _.orderBy(fullHistory, ["filledTimestamp"], ["desc"]);
          let data = {
            gobalHistory: { ...demo },
            fullHistory: { ...demo }
          };
          let token = {
            ...result,
            ...info,
            logoId: logoId
          };
          tmpLoading.updateContent = false
          tmpLoading.symbol = info.symbol
          tmpLoading.address = info.address
          dispatch(actionCreatorsLoading.loading(tmpLoading))
          dispatch(actionCreatorsHistory.loading(data));
          dispatch(actionCreatorsToken.loading(token));
        } else {
          const symbol = e.target.value;
          const result = await Service.getTokenSymbol(symbol);
          if (result.hasOwnProperty("errorMessage")) {
            const message = {
              isShow: true,
              message: result.errorMessage
            };
            tmpLoading.updateContent = false
            dispatch(actionCreatorsLoading.loading(tmpLoading))
            dispatch(actionCreatorsMessage.loading(message));
          } else {
            if (result.length > 1) {
              console.log('many coin');
            } else {
              console.log('one coin');
              const info = result.data[0];
              const infoDetail = await Service.getTokenEth(info.token.address);
              let paramCheckCoin = {
                symbol: info.token.symbol,
                currency: 'USD'
              }
              const WETH = await Service.getPriceInfo(paramCheckCoin)
              let logoId = null
              if (!WETH.hasOwnProperty("message")) {
                logoId = WETH.data.id
              }
              const fullHistory = await Service.getFullHistoryToken(
                info.token.symbol
              );
              const demo = _.orderBy(
                fullHistory,
                ["filledTimestamp"],
                ["desc"]
              );
              let data = {
                gobalHistory: { ...demo },
                fullHistory: { ...demo }
              };
              let token = {
                ...infoDetail,
                data: { ...info },
                logoId: logoId
              };
              tmpLoading.updateContent = false
              tmpLoading.symbol = info.token.symbol
              tmpLoading.address = info.token.address
              dispatch(actionCreatorsHistory.loading(data));
              dispatch(actionCreatorsToken.loading(token));
              dispatch(actionCreatorsLoading.loading(tmpLoading))
            }
          }
        }
      } else {
        const message = {
          isShow: true,
          message: "value input not empty"
        };
        dispatch(actionCreatorsMessage.loading(message));
        dispatch(actionCreatorsLoading.loading(tmpLoading));
      }
    }
  };
  handelOnChange = (e) => {
    this.setState({
      searchKey: (e.target.value).trim(),
      isShow: true
    })
  }
  handelChildSuggess = async (item) => {
    this.setState({
      searchKey: item.symbol,
      isShow: false
    })
    await ReduxService.searchTokenByAddress(item.address)
  }
  render() {
    return (
      <div className="search">
        <form className="form-inline">
          <FontAwesomeIcon color="#BDBDBD" icon={faSearch} />
          <input
            type="text"
            onKeyPress={this.handelKeyPress}
            onChange={this.handelOnChange}
            className="form-control btnSearch"
            placeholder="Search by Address/Token"
            aria-label="Search"
            ref={this.input}
            value={this.state.searchKey}
          />
        </form>
        <div className="line" />
        <SearchSuggest isShow={this.state.isShow} keyword={this.state.searchKey} data={this.props.coins} handelChildSuggess={this.handelChildSuggess} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
  coins: state.reducerBasicCoin.coins
});
export default connect(mapStateToProps)(Search);
