import { WEB_CONFIG } from "helper/globalConstant";
class Service {
  static getToken(address = WEB_CONFIG.ADDRESS_DEFAULT) {
    let url = `${WEB_CONFIG.HOST_TS_API}/token/load?address=${address}`;
    return this.fetchData(url);
  }
  static getTokenSymbol(symbol = WEB_CONFIG.SYMBOL) {
    let url = `${WEB_CONFIG.HOST_TS_API}/token/load?symbol=${symbol}`;
    return this.fetchData(url);
  }
  static getTokenEth(address = WEB_CONFIG.ADDRESS_DEFAULT) {
    let url = `${WEB_CONFIG.HOST_ETHPLORER}/getTokenInfo/${address}?apiKey=freekey`;
    return this.fetchData(url);
  }
  static getHistoryToken(token = WEB_CONFIG.SYMBOL, page = 0) {
    let url = `${WEB_CONFIG.HOST_TS_API}/orders?from=${token}&to=WETH&skip=${page}&limit=${WEB_CONFIG.NUMBER_PAGIN}&statusCode=1`;
    return this.fetchData(url);
  }
  static getFullHistoryToken(token = WEB_CONFIG.SYMBOL) {
    let url = `${WEB_CONFIG.HOST_API}/orders/all?from=${token}&to=WETH&statusCode=1`;
    return this.fetchData(url);
  }
  static async getPriceInfo(param) {
    let url = `${WEB_CONFIG.HOST_API}/coin/info`;
    return this.fetchDataPOST(url, param);
  }
  static async getAllCoin() {
    let url = `${WEB_CONFIG.HOST_API}/token/list`;
    return this.fetchDataPOST(url, {});
  }
  static async fetchData(apiurl) {
    try {
      let response = await fetch(apiurl);
      let responJson = await response.json();
      return responJson;
    } catch (error) {
      console.warn(error);
      return [];
      // coment throw error
    }
  }
  static async fetchDataPOST(apiurl, body) {
    try {
      let response = await fetch(apiurl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        mode: 'cors', 
        method: "POST",
        body: JSON.stringify(body)
      });
      let responJson = await response.json();
      return responJson;
    } catch (error) {
      console.warn(error);
      return [];
      // coment throw error
    }
  }
}
export default Service;
