import React from "react";
import "./style.css";
import Images from "asset/images";
import Filter from "component/filter";
import { FadingCircle } from 'better-react-spinkit'
import { numberWithCommas, convertWeiToBalance,formatPriceNumber, round, formatNumberLeftZero } from 'helper'
import { connect } from 'react-redux'
import {WEB_CONFIG} from 'helper/globalConstant'
class Token extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true
    };
  }
  componentDidMount() {
    this.setState({ isloading: false })
  }
  renderLogo = (symbol, logoId) => {
    if (logoId == null) {
      if(symbol === 'HBWALLET') {
        return (<img className="coin-logo" src={Images.imgLogo} />)
      } else {
        return(<img className="no-coin" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAADt0lEQVR4Xu3dIXZUQRRF0epJhCmw8BEoiEbEMhxkRoHGRqAjEZGMgjhGAIsxbHFXkxP/uqrvO/9U/U797svvnx/+HPh7//0XVJ9zc/OZ6q+9+OXlG72FH5/eUP0lACg/Lg6ADEAQZQCKb1+cATIAUZgBKL59cQbIAERhBqD49sUZIAMQhRmA4tsXZ4AMQBRmAIpvX5wBMgBRmAEovn1xBsgARGEGoPj2xXMDvHt4S+cBlMBrP0+wbqDmdwkAs0AA4IkUJXh9oigAAoAUsl5CWwKofedkgAxACGWAKz9VnAEyQAaQBLoLsHP9ml+bQKH3tAk8601MnwPYk1kZIAP0vwBhoLuA7gKEn/kS2hJA7WsTOCe4TSBuAj9+/ULnAfACOo+3T/QSeh9Mg5/DF8D9851OgeovAUD5BYDFdzJABmgJ0ItI6lsCJL32AJjeaQloE9hdgF9F8AotARDev1L9Z1gGyACIoJVnAMsvA2B+fQ7Q5wB9DqAXkdS3BEh6bQIxvT4HON0FdBfgVxG8QksAhPdffA6gj4frgQw9U6fjY//52UCdv+bHR8LWb0DHDwD8hhBtgBKs4wdAABADa4B1/JYAar+fClaDBcD4a+a0AQGAv5qlAaIAugvQBqyvoABoE0gMrAHW8dsEUvvbBPIvfyrBugRh/9sDaAMCwH46V/NrCUAFaAPWF1AABIB9Q8iaYB0f+98eQBuwVuirB0AfD9cGagNee71egHwiKAC2CAbANv/56AEwb8F2AgGwzX8+egDMW7CdQABs85+PHgDzFmwnEADb/OejB8C8BdsJBMA2//noATBvwXYCAbDNfz56AMxbsJ1AAGzzn48eAPMWbCfAAOj3A2zfvn9Ro85//XsFOn8+E6gT0Hr9pk4dPwA0QawPAAswA1h+JwNggFqeASzBDGD5ZQDMj8szgEWYASy/DID5cXkGsAgzgOWXATA/Ls8AFmEGsPwyAObH5RnAIswAll8GwPy4PANYhBnA8ssAeiAB83/15fp4PhsgALYMBsA2//noATBvwXYCAbDNfz56AMxbsJ1AAGzzn48eAPMWbCcQANv856MHwLwF2wkEwDb/+egBMG/BdgIBsM1/PnoAzFuwnUAAbPOfjx4A8xZsJzAHQE/k3D/fbRMcj/54+0Qz0IdT+TxAAFD/TgBkACIoA1B8++IMkAGIwgxA8e2LM0AGIAozAMW3L84AGYAozAAU3744A2QAojADUHz74gyQAYjCDEDx7YszQAYgCjMAxbcvXhvgL6GG4D4YJmehAAAAAElFTkSuQmCC"/>)
      }
    }else {
      return (<img className="coin-logo" src={WEB_CONFIG.URL_IMAGE_COIN + logoId+ '.png'} />)
    } 
  }
renderAmountFormat = (amount, decimals) => {
  const firstNumber = formatNumberLeftZero(amount, decimals).toString().split('.')[0]
  const secondNumber = formatNumberLeftZero(amount, decimals).toString().split('.')[1]
  return (
    <div style={{display: 'inline-block'}}>
      <span className="exc-usd-first">{firstNumber}</span>
      <span className="exc-usd-second">{secondNumber ? '.' + secondNumber : ''}</span>
    </div>
  )
}
renderPriceFormat = (price, decimals) => {
  const firstNumber = formatPriceNumber(price, decimals).toString().split('.')[0]
  const secondNumber = formatPriceNumber(price, decimals).toString().split('.')[1]
  return (
    <div style={{display: 'inline-block'}}>
      <span className="exc-usd-first">{firstNumber}</span>
      <span className="exc-usd-second">{secondNumber ? '.' + secondNumber : ''}</span>
    </div>
  )
}
renderUsdPriceFormat = (price, decimals) => {
  const firstNumber = formatPriceNumber(price, decimals).toString()
  return (
   <span>{firstNumber}</span>
  )
}
  render() {
    const { data, basic, isUpdate } = this.props
    const { isloading } = this.state
    const tokenInfo = { ...data }
    const basicDetail = { ...basic }
    const tokenDetail = tokenInfo.data && tokenInfo.data.token || {}
    let currentPrice = 0
    let currentETH = 0
    if (tokenInfo.data && basic.WETH) {
      const price = tokenInfo.data.lastPrice || 0
      currentPrice = Math.abs(price.price)
      currentETH = basicDetail.WETH.priceCurrency
    }
    const totalSupply = numberWithCommas(convertWeiToBalance(data.totalSupply, tokenDetail.decimals))
    const valueCoin = numberWithCommas(currentPrice * convertWeiToBalance(data.totalSupply, tokenDetail.decimals) * currentETH)
    const priceCoin = formatPriceNumber(currentETH * currentPrice,tokenDetail.decimals)
    return (
      !isloading && tokenInfo.data && tokenInfo.data.token ?
        <div className="container">
          <section className="logo-token">
            <div>
              <div className="inside-logo">
               {this.renderLogo(tokenInfo.symbol, tokenInfo.logoId)}
              </div>
              <span className="Sologan-small">{data.name}</span>
            </div>
          </section>
          <div className="token">
            <div className="container row token-inside">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-3 itemTitle">Total Supply</div>
                  <div className="col-lg-9 itemValue">{totalSupply} {data.symbol} (${valueCoin})  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 itemTitle"> Price</div>
                  <div className="col-lg-9 itemValue">${priceCoin} @ {formatNumberLeftZero(currentPrice)} Eth</div>
                </div>
                <div className="row">
                  <div className="col-lg-3 itemTitle">Holders</div>
                  <div className="col-lg-9 itemValue">{data.holdersCount} addresses</div>
                </div>
                <div className="row">
                  <div className="col-lg-3 itemTitle">Transfers</div>
                  <div className="col-lg-9 itemValue">{data.transfersCount}</div>
                </div>
                <div className="row">
                  <div className="col-lg-3 itemTitle">Offical Site</div>
                  <div className="col-lg-9 itemValue"><a href={tokenDetail.homePage} target="_blank">{tokenDetail.homePage}</a></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-sm-3 itemTitle">contract</div>
                  <div className="col-sm-9 itemValue"><a href={`https://etherscan.io/address/${tokenDetail.address}`} target="_blank">{tokenDetail.address}</a></div>
                </div>
                <div className="row">
                  <div className="col-sm-3 itemTitle">Decimals</div>
                  <div className="col-sm-9 itemValue">{tokenDetail.decimals}</div>
                </div>
                <div className="row">
                  <div className="col-sm-3 itemTitle">Links</div>
                  <div className="col-sm-9 itemValue">
                    <ul className="listSocial">
                      {tokenDetail.hasOwnProperty('email') && tokenDetail.email !== null && <a className="iconSocial" href={tokenDetail.email} target="_blank"><img src={Images.icEmail} /> </a>}
                      {tokenDetail.hasOwnProperty('blog')  && tokenDetail.blog !== null && <a className="iconSocial" href={tokenDetail.blog} target="_blank"><img src={Images.icBlog} /></a>}
                      {tokenDetail.hasOwnProperty('slack') && tokenDetail.slack !== null && <a className="iconSocial" href={tokenDetail.slack} target="_blank"><img src={Images.icSlack} /></a>}
                      {tokenDetail.hasOwnProperty('facebook') && tokenDetail.facebook !== null && <a className="iconSocial" href={tokenDetail.facebook} target="_blank"><img src={Images.icFacebookcl} /></a>}
                      {tokenDetail.hasOwnProperty('twitter') && tokenDetail.twitter !== null && <a className="iconSocial" href={tokenDetail.twitter} target="_blank"><img src={Images.icTwittercl} /></a>}
                      {tokenDetail.hasOwnProperty('telegram') && tokenDetail.telegram !== null && <a className="iconSocial" href={tokenDetail.telegram} target="_blank"><img src={Images.icTelegramcl} /></a>}
                      {tokenDetail.hasOwnProperty('whitepaper') && tokenDetail.whitepaper !== null && <a className="iconSocial" href={tokenDetail.whitepaper} target="_blank"><img src={Images.icWhitepaper} /></a>}
                      {tokenDetail.hasOwnProperty('reddit') && tokenDetail.reddit !== null && <a className="iconSocial" href={tokenDetail.reddit} target="_blank"><img src={Images.icRedditcl} /></a>}
                      {tokenDetail.hasOwnProperty('linkedin') && tokenDetail.linkedin !== null && <a className="iconSocial" href={tokenDetail.linkedin} target="_blank"><img src={Images.icLinkedincl} /></a>}
                      {tokenDetail.hasOwnProperty('youtube')  && tokenDetail.youtube !== null && <a className="iconSocial" href={tokenDetail.youtube} target="_blank"><img src={Images.icYoutubecl} /></a>}
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 itemTitle">Filtered by</div>
                  <div className="col-sm-9 itemValue">
                    <Filter />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        : null
        // <div className="container"><div className="loading"><FadingCircle size={50} /></div></div>
    );
  }
}
const mapStateToProps = (state) => ({
  isUpdate: state.reducerLoading
})
export default connect(mapStateToProps)(Token)