import * as reducerHistory from "./history";
import * as reducerToken from "./token";
import * as reducerMessage from "./message";
import * as reducerBasicCoin from "./basicCoin";
import * as reducerLoading from "./loading";
import * as reducerCoin from "./coin";
import { combineReducers } from "redux";
import { loadingBarReducer } from 'react-redux-loading-bar'

const rootReducer = combineReducers({
  ...reducerHistory,
  ...reducerToken,
  ...reducerMessage,
  ...reducerBasicCoin,
  ...reducerLoading,
  ...reducerCoin,
  loadingBar: loadingBarReducer,
});

export default rootReducer;
