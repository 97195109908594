import { types } from "../constants/constant";
import { initialState } from "../constants/initialState";

export const reducerLoading = (state = initialState.loading, action) => {
  const { data } = state;
  const { type, payload } = action;

  switch (type) {
    case types.UPDATE_CONTENT: {
      return {
        ...state,
        ...payload
      };
    }
  }

  return state;
};
